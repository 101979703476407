import axios from 'axios';

export const addField = field => {
    return axios.post('fields/', field);
}

export const updateField = field => {
    return axios.put(`fields/${field.id}/`, field);
}

export const deleteField = id => {
    return axios.delete(`fields/${id}/`);
}

export const getFieldTypes = () => {
    return axios.get('field_types/');
}

export const getDateFormats = () => {
    return axios.get('date_formats/');
}

export const addDateFormat = dateFormat => {
    return axios.post('date_formats/', dateFormat);
}