<template>
    <div class="modal fade" id="delete-object-modal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <b class="modal-title" id="deleteItemTitle">Confirm delete</b>
                </div>
                <div class="modal-body">Are you sure you want to delete {{ currentObject.name }}?</div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm btn-light" data-dismiss="modal">Close</button>
                    <SubmitButton
                        @click-submit="deleteObject"
                        :text="'Delete'"
                        :btnClass="'btn-danger'"
                        :btnDisabled="deleting"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { deleteField } from '../../services/FieldService';
import { deleteProduct } from '../../services/ProductService';
import SubmitButton from '../shared/SubmitButton.vue';

export default {
    name: 'DeleteObjectModal',
    components: {
        SubmitButton
    },
    props: {
        currentObject: Object,
        type: String
    },
    data() {
        return {
            deleting: false,
            deleteObj: {
                product: deleteProduct,
                field: deleteField
            }
        };
    },
    methods: {
        async deleteObject() {
            try {
                this.deleting = true;
                if (this.type === 'field' && this.currentObject.id) {
                    this.deleteObj[this.type](this.currentObject.id);
                }
                this.$emit('delete-object', this.currentObject.type_name);
            } catch (error) {
                console.log(error);
            }
            $('#delete-object-modal').modal('hide');
            this.deleting = false;
        }
    }
};
</script>
